<template>
    <div class="wrap-item-mega-slide">
        <div class="single-item-element">
            <div class="wrap-paginazione-item-slide">
                <span class="wrap-arrow">
                    <a v-show="index > 0" @click.prevent="prev" href=""><img ref="arnsx"></a>
                </span>

                <div class="wrap-paging">
                    <span class="mega-current-slide">{{index + 1}}</span>
                    <span class="mega-slash-slide">/</span>
                    <span class="mega-current-slide">{{total}}</span>
                </div>

                <span class="wrap-arrow">
                    <a v-show="index < total - 1" @click.prevent="next" href=""><img ref="arndx"></a>
                </span>
            </div>
            <div class="wrap-dida-single-item">
                <div class="single-slide">
                    <div class="wrap-img-mega-slide col-md-8 offset-md-4">
                        <div class="wrap-relative img-format"
                             v-for="(elem, i) in elementsChunk"
                             :key="elem.id"
                             :id="elem.id"
                             :style="(i > 0 ? 'position:absolute;' : '') + 'z-index:'+ (elements.length - i) +';background: url(' + imgPrefix + elem.image + ') no-repeat center center; background-size: cover;background-color:#f0f0f0;'"></div>
                    </div>
                    <div class="wrap-content-dida-slide" :class="'wrap-' + color" @click="goToScheda">
                        <h2 class="title-single-slide">{{slide.title}}</h2>
                        <h2 class="anno-single-slide">{{slide.date}}</h2>

                        <div class="info-single-slide"><span>{{slide.localizzazione}}</span></div>
                        <div class="info-single-slide"><span>{{slide.produttore}}</span></div>
                        <div class="info-single-slide"><span>{{slide.riedizione | join}}</span></div>

                        <div class="archivio-single-slide">/ {{slide.type}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import arrowSX from '../assets/freccia-nera-paginazione-sx.svg'
    import arrowDX from '../assets/freccia-nera-paginazione-dx.svg'
    import anime from "animejs/lib/anime.es.js";

    export default {
        name: "Slider",
        props: {
            elements: Array,
            color: String
        },
        data: () => ({
            index: 0,
        }),
        computed: {
            elementsChunk() {
                return this.elements ? this.elements.slice(0, this.index + 5) : [];
            },
            total(){
                return this.elements ? this.elements.length : 0
            },
            slide() {
                return this.elements ? this.elements[this.index] : {}
            },
            prevSlide() {
                return this.elements ? this.elements[this.index - 1] : {}
            },
            imgPrefix() {
                return window.IMAGE_PREFIX || '';
            }
        },
        methods: {
            next() {
                anime({
                    targets: "#" + this.slide.id,
                    translateX: -360,
                    duration: 600,
                    easing: "easeInQuad"
                });

                this.index += 1;
            },
            prev() {
                anime({
                    targets: "#" + this.prevSlide.id,
                    translateX: 0,
                    duration: 600,
                    easing: "easeOutQuad"
                });

                this.index -= 1;
            },
            goToScheda() {
                window.location.href = `${window.SCHEDA_URL_PREFIX}/${this.slide.id}`
            }
        },
        filters: {
            join(value) {
                if (!value || !Array.isArray(value) || value.length < 1)
                    return '';

                return value.join(', ')
            }
        },
        mounted() {
            this.$refs.arnsx.src = arrowSX;
            this.$refs.arndx.src = arrowDX;
        }
    }
</script>

<style scoped>

    .wrap-arrow {
        width: 12px;
        display: inline-block;
        z-index: 9999;
    }

    .wrap-paging {
        display: inline-block;
        width: 50px;
        text-align: center;
    }

    .single-item-element {
        position: relative;
    }

    .wrap-paginazione-item-slide {
        position: absolute;
        top: 33%;
        margin-left: 15px;
        z-index: 10000;
    }

    .wrap-paginazione-mega-slide img {
        height: 15px;
        margin-top: -5px;
    }

    .wrap-paginazione-item-slide {}

    .wrap-paginazione-item-slide span {
        font-weight: 400;
        font-size: 18px;
        color: #212121;
    }

    .wrap-paginazione-item-slide img {
        height: 12px;
    }

    /* CSS for single slide*/
    .wrap-img-mega-slide {
        text-align: right;
        padding-right: 0;
        padding-left: 0;
        overflow: hidden;
    }

    .single-slide {
        position: relative;
    }

    .wrap-relative {
        position: relative;
    }

    .wrap-content-dida-slide {
        position: absolute;
        top: 40%;
        left: 0;
        z-index: 1000;
        min-height: 50%;
        padding: 15px;
        max-width: 270px;
        text-align: left;
        min-width: 270px;
        cursor: pointer;
    }

    .wrap-yellow {
        background-color: #fedd3a;
        /*left: -50%;*/
    }

    .wrap-blue {
        background-color: #76c4ee;
        /*left: -50%;*/
    }

    .img-format {
        width: 100%;
        height: 100%;
        min-height: 520px;
        top: 0;
        left: 0;
        background-size: cover;
    }

    .title-single-slide {
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
    }

    .anno-single-slide {
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        margin: 0;
    }

    .info-single-slide {
        margin-top: 28px;
    }

    .info-single-slide span {
        display: block;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 14px;
    }

    .archivio-single-slide {
        margin-top: 0;
        position: absolute;
        bottom: 15px;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 10px;
    }
</style>