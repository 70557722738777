const MESSAGES = {
    'it': {
        'cmp.app.years': 'anni',
    },
    'en': {
        'cmp.app.years': '',
    }
};

export default function message(name) {
    return MESSAGES[window.LANG][name] || '';
}
