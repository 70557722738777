import axios from "axios"

const ax = axios.create({
    baseURL: window.API_URL,
    responseType: 'json',
    responseEncoding: 'utf8',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json'
    }
});

const API = () => ({
    getAll(year /*int*/) {
        return ax.get('/all', {
            params: { d: year, rnd: new Date().getTime() }
        }).then(value => value.data)
    }
});

export default API();
