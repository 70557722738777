<template>
    <div id="app" class="container container-mega-slide">
        <div class="row wrap-top-mega-slide">
            <div class="col-md-5">
                <h2 class="title-timeline-slide"><a :href="timelineUrl">timeline</a></h2>
            </div>
            <div class="col-md-7 wrap-paginazione-mega-slide">

                <span class="wrap-paging-mega">
                    <a v-show="!tldata.first"
                       @click.prevent="prevDecade"
                       href="" class="mega-slide-btn-prev"><img ref="arasx"></a>
                </span>
                <span class="mega-slide-anno width-anno">{{message('cmp.app.years')}} {{tldata.yearWord}}</span>
                <span class="wrap-paging-mega">
                    <a v-show="!tldata.last"
                       @click.prevent="nextDecade"
                       href="" class="mega-slide-btn-next"><img ref="aradx"></a>
                </span>
            </div>
        </div>
        <div class="row row-body-slide">
            <div class="col-md-6 wrap-slide-sx">
                <slider :elements="tldata.design" :key="'des'-tldata.year" color="yellow" />
            </div>

            <div class="col-md-6 wrap-slide-dx">
                <slider :elements="tldata.architettura" :key="'arch'-tldata.year" color="blue" />
            </div>
        </div>
    </div>
</template>

<script>
    import API from './api'
    import Slider from './components/Slider'
    import arrowSX from './assets/freccia-arancione-sx.svg'
    import arrowDX from './assets/freccia-arancione-dx.svg'
    import message from "./i18n";

    export default {
        name: 'app',
        components: {
            Slider
        },
        data: () => ({
            tldata: {},
        }),
        computed: {
            timelineUrl(){
                return window.TIMELINE_URL;
            }
        },
        methods: {
            message: message,
            update(year) {
                API.getAll(year).then(data => {
                    this.tldata = data;
                })
            },
            nextDecade() {
                this.update(this.tldata.year + 10)
            },
            prevDecade() {
                this.update(this.tldata.year - 10)
            }
        },
        created() {
            this.update(1940)
        },
        mounted() {
            this.$refs.arasx.src = arrowSX;
            this.$refs.aradx.src = arrowDX;
        }
    }
</script>

<style>
    .wrap-paging-mega {
        width: 15px;
        display: inline-block;
    }

    .width-anno {
        width: 178px;
        text-align: center;
        display: inline-block;
    }

    .row-body-slide {
        margin-top: 13px;
    }

    .title-timeline-slide, .title-timeline-slide a{
        font-weight: 400;
        text-transform: uppercase;
        font-size: 24px;
        color: #d96036;
        margin-top: 7px;
        margin-bottom: 0;
    }

    .wrap-paginazione-mega-slide {
        padding-left: 64px;
    }

    .mega-slide-anno {
        margin: 0 25px;
        font-weight: 400;
        font-size: 24px;
        color: #d96036;
    }

    .wrap-paginazione-mega-slide img {
        height: 15px;
        margin-top: -5px;
    }
</style>
